import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Section from "components/Section"
import Button from "components/Button"

const Office = () => {
  const { t } = useLocale()

  return (
    <Section className="home-office">
      <h2>{t("Kancelaria")}</h2>
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <div
            className="home-office__heading"
            dangerouslySetInnerHTML={{
              __html: `<h3>${t(
                "Jesteśmy wysoko wyspecjalizowaną,<br/>butikową kancelarią działającą na rynku międzynarodowym<br/>i realizującą transakcje w Polsce i za granicą już od 30 lat."
              )}</h3>`,
            }}
          />
          <img
            src={require("assets/images/home-office-image.jpg").default}
            alt=""
          />
          <div className="row">
            <div
              className="col-lg-5 offset-lg-7"
              dangerouslySetInnerHTML={{
                __html: `<p>${t(
                  "Tworzymy skuteczne rozwiązania wspierające<br/>biznes naszych Klientów."
                )}</p>`,
              }}
            />
          </div>
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="home-office__cta">
                <Button to={t("/o-kancelarii/")}>
                  {t("Dowiedz się więcej")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Office
