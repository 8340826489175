import "./styles.scss"

import React from "react"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import { Link, withPrefix } from "gatsby"

import { useLocale } from "context/locale"

import Section from "components/Section"

import mapStyle from "utils/mapStyle"

const containerStyle = {
  width: "100%",
  height: "490px",
}

const krakowLatLng = {
  lat: 50.03421302025668,
  lng: 19.93833302654743,
}

const warszawaLatLng = {
  lat: 52.2287556,
  lng: 21.009704,
}

const Content = ({ zoom = 16 }) => {
  const { t } = useLocale()

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAXyO5owtkigL1pb6jBjs2Auigqe7dN5O8",
  })

  return (
    <Section className="contact-content" type="2" top={false}>
      <div className="row">
        <div className="col-lg-5 col-xl-4 offset-xl-1">
          <div className="contact-content__inner">
            <h3>
              Kancelaria Radców Prawnych
              <br /> Oleś, Rysz, Sarkowicz sp.k.
            </h3>
            <div className="row">
              <div className="col-xxl-6">
                <h4 className="h3">Kraków</h4>
              </div>
              <div className="col-xxl-6">
                <address>
                  <p>
                    ul. Wadowicka 3c
                    <br />
                    30-347 Kraków
                  </p>
                  <p>
                    e-mail:{" "}
                    <a href="mailto:kancelaria@oles.com.pl">
                      kancelaria@oles.com.pl
                    </a>
                    <br />
                    RODO: <a href="mailto:rodo@oles.com.pl">rodo@oles.com.pl</a>
                  </p>
                  <p>
                    tel.: <a href="tel:+48 12 4314325">+48 12 4314325</a>
                    <br />
                    <a href="tel:+48 12 4280630">+48 12 4280630</a>
                    <br />
                    fax: <a href="tel:+48 12 4314327">+48 12 4314327</a>
                  </p>
                </address>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="google-map">
            {isLoaded ? (
              <GoogleMap
                center={krakowLatLng}
                zoom={zoom}
                options={{ styles: mapStyle }}
                mapContainerStyle={containerStyle}
              >
                <Marker
                  icon={withPrefix("/ico-pointer.svg")}
                  position={krakowLatLng}
                />
              </GoogleMap>
            ) : null}
          </div>
        </div>
      </div>

      <div className="contact-content__divider" />

      <div className="row">
        <div className="col-lg-5 col-xl-4 offset-xl-1">
          <div className="contact-content__inner">
            <div className="row">
              <div className="col-xxl-6">
                <h4 className="h3">Warszawa</h4>
              </div>
              <div className="col-xxl-6">
                <address>
                  <p>
                    ul. Poznańska 37
                    <br />
                    00-689 Warszawa
                  </p>
                </address>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="google-map">
            {isLoaded ? (
              <GoogleMap
                center={warszawaLatLng}
                zoom={zoom}
                options={{ styles: mapStyle }}
                mapContainerStyle={containerStyle}
              >
                <Marker
                  icon={withPrefix("/ico-pointer.svg")}
                  position={warszawaLatLng}
                />
              </GoogleMap>
            ) : null}
          </div>
        </div>
      </div>

      <p className="contact-content__info">
        {t("Prosimy o zapoznanie się")}{" "}
        <Link to={t("/polityka-prywatnosci/")}>
          {t("z informacją dotyczącą przetwarzania danych osobowych")}
        </Link>
      </p>
    </Section>
  )
}

export default Content
