import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

const Hero = () => {
  const { t } = useLocale()

  return (
    <section className="home-hero">
      <div className="container-fluid">
        <h2>{t("Strategiczne rozwiązania dla Twojego Biznesu")}</h2>
      </div>
    </section>
  )
}

export default Hero
