import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Section from "components/Section"
import NumericList from "components/NumericList"

const Competences = ({ data }) => {
  const { t } = useLocale()

  return (
    <Section
      className="home-competences"
      type="2"
      after={
        <div className="home-competences__image">
          <img
            src={require("assets/images/home-competences-image.jpg").default}
            alt=""
          />
        </div>
      }
    >
      <h2>{t("Kompetencje")}</h2>
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <NumericList data={data} isLight />
        </div>
      </div>
    </Section>
  )
}

export default Competences
