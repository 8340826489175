import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Section from "components/Section"
import ContactForm from "components/ContactForm"

const Contact = () => {
  const { t } = useLocale()

  return (
    <Section className="home-contact" type="2">
      <div className="row">
        <div
          className="col-lg-5"
          dangerouslySetInnerHTML={{
            __html: `<h2>${t("Skontaktuj się z nami")}</h2>`,
          }}
        />
        <div className="col-lg-7 col-xl-5 offset-xl-1">
          <ContactForm />
        </div>
      </div>
    </Section>
  )
}

export default Contact
