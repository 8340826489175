import "./styles.scss"

import React from "react"
import { Link } from "gatsby"
import clsx from "clsx"

import { useLocale } from "context/locale"

const NumericList = ({ data, isLight }) => {
  const { t } = useLocale()

  return (
    data?.length > 0 && (
      <div className={clsx("numeric-list", isLight && "numeric-list--light")}>
        {data.map(({ title, slug }, index) => (
          <Link
            to={`${t("/kompetencje/")}${slug}/`}
            className="numeric-list__item"
            key={index}
          >
            <span className="numeric-list__counter">
              <span className="numeric-list__counter-number">
                0{index + 1}.
              </span>
            </span>
            {title}
          </Link>
        ))}
      </div>
    )
  )
}

export default NumericList
