import "./styles.scss"

import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import { useLocale } from "context/locale"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"

const ContactForm = () => {
  const { t } = useLocale()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const formID = "8"
  const [send, setSend] = useState(false)
  const [error, setError] = useState(null)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)

  const formSubmit = async e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", `${firstName} ${lastName}`)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("message", message)
    formData.set("legalFirst", legalFirst)

    if (!executeRecaptcha) {
      setError(
        t("Wystąpił błąd. Prosimy o odświeżenie strony i spróbowanie ponownie.")
      )
      setSend(false)
    } else {
      const token = await executeRecaptcha("contact_form")

      formData.set("_wpcf7_recaptcha_response", token)

      axios({
        method: "post",
        url: `https://cms.oles.com.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(data => {
          if (data?.data?.status === "spam") {
            setError(
              t("Wystąpił błąd podczas wysyłania formularza. Spróbuj ponownie później.")
            )
            setSend(false)
          } else {
            navigate("/kontakt-potwierdzenie/")
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const handleOnChange = (e, callback) => {
    const value = e.target.value

    if (error) setError(null)
    if (callback && typeof callback === "function") callback(value)
  }

  return (
    <form onSubmit={formSubmit}>
      <div className="row">
        <div className="col-md-6">
          <Input
            label={t("Imię")}
            type="text"
            id="firstName"
            name="firstName"
            value={firstName}
            onChange={e => handleOnChange(e, setFirstName)}
          />
        </div>
        <div className="col-md-6">
          <Input
            label={t("Nazwisko")}
            type="text"
            id="lastName"
            name="lastName"
            value={lastName}
            onChange={e => handleOnChange(e, setLastName)}
          />
        </div>
        <div className="col-md-6">
          <Input
            label={t("Telefon")}
            type="phone"
            id="phone"
            name="phone"
            value={phone}
            onChange={e => handleOnChange(e, setPhone)}
          />
        </div>
        <div className="col-md-6">
          <Input
            label={t("Email*")}
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={e => handleOnChange(e, setEmail)}
            required
          />
        </div>
        <div className="col-md-12">
          <Input
            label={t("Treść wiadomości")}
            type="textarea"
            id="message"
            name="message"
            value={message}
            onChange={e => handleOnChange(e, setMessage)}
            rows="4"
          />
        </div>
      </div>

      {error && (
        <div className="form__error">
          <p>{error}</p>
        </div>
      )}

      <div className="row align-items-center">
        <div className="col-sm-7">
          <Checkbox
            name="legalFirst"
            checked={legalFirst}
            onChange={() => setLegalFirst(!legalFirst)}
            required
          >
            {t("Przeczytałem i akceptuję")}{" "}
            <a
              href={t("/polityka-prywatnosci/")}
              target="_blank"
              rel="noreferrer"
            >
              {t("Politykę Prywatności")}
            </a>
          </Checkbox>
        </div>
        <div className="col-sm-5">
          <div className="form__cta">
            <Button
              type="submit"
              disabled={!legalFirst || error}
              className="button--form"
            >
              {t(send === true ? "Wysyłanie..." : "Wyślij")}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
