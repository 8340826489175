import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Section from "components/Section"
import Button from "components/Button"

const Team = () => {
  const { t } = useLocale()

  return (
    <Section className="home-team">
      <h2>{t("Zespół")}</h2>
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <div
            className="home-team__heading"
            dangerouslySetInnerHTML={{
              __html: `<h3>${t(
                "Świadczenie usług przez Kancelarię opiera się na osobistej pracy Wspólników<br/> w zakresie aspektów strukturalnych i strategicznych realizowanych projektów,<br/>przy wsparciu wykonawczym kilkunastoosobowego zespołu prawników."
              )}</h3>`,
            }}
          />
          <img
            src={require("assets/images/home-team-image.jpg").default}
            alt=""
          />
          <div className="row">
            <div className="col-lg-7 offset-lg-5">
              <p>
                {t(
                  "Naszym wyróżnikiem jest znakomite zrozumienie procesów biznesowych, co pozwala na skuteczną realizację celów ekonomicznych naszych Klientów."
                )}
              </p>
              <Button to="/zespol/">{t("Dowiedz się więcej")}</Button>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Team
